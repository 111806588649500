import React from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { Link } from "gatsby";
import avi from "../images/avi.jpg";
const Intro = () => {
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col items-center">
        <div
          style={{
            backgroundImage: `url(${avi})`,
            backgroundPosition: "center bottom",
            backgroundSize: "cover",
          }}
          className="h-[8rem] w-[8rem] rounded-full"
        ></div>
        <h1 className="px-4 pt-4 text-2xl font-semibold">
          Hello, I&apos;m Olujimi.
        </h1>
      </div>
      <p className="text-sm leading-loose text-dark-grey">
        I am a
        <span className="animate-flash text-white opacity-90"> versatile </span>
        <span className="animate-flashTwo text-white opacity-90">
          frontend{" "}
        </span>
        <span className="animate-flashTwo text-white opacity-90">
          developer{" "}
        </span>
        with a passion{" "}
        <span className="animate-flash text-white opacity-90">
          for cutting-edge technologies,{" "}
        </span>
        dedicated to{" "}
        <span className="animate-flashTwo text-white opacity-90">
          crafting robust,{" "}
        </span>
        {/* <span className="animate-flash text-white opacity-90"> */}
        user-centric solutions that{" "}
        <span className="animate-flashTwo text-white opacity-90">
          enhance user experience{" "}
        </span>
        and deliver{" "}
        <span className="animate-flash text-white opacity-90">
          innovative web applications
        </span>
        .
      </p>

      <div className="m-1 my-5 flex items-center justify-center">
        <Link
          to="/work"
          className="flex items-center gap-x-2 bg-box-bg px-8 py-[0.6rem] text-xs text-white"
        >
          <span>Explore</span>
          <BsFillCaretRightFill />
        </Link>
      </div>
    </div>
  );
};

export default Intro;
